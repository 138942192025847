@font-face {
  font-family: 'metafi';
  src:  url('@core/assets/fonts/metafi.eot?m36uhe');
  src:  url('@core/assets/fonts/metafi.eot?m36uhe#iefix') format('embedded-opentype'),
    url('@core/assets/fonts/metafi.ttf?m36uhe') format('truetype'),
    url('@core/assets/fonts/metafi.woff?m36uhe') format('woff'),
    url('@core/assets/fonts/metafi.svg?m36uhe#metafi') format('svg');
  // font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="metafi-"], [class*=" metafi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'metafi' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.metafi-flagvietnam .path1:before {
  content: "\e9bc";
  color: rgb(218, 37, 29);
}
.metafi-flagvietnam .path2:before {
  content: "\e9bd";
  margin-left: -1.3154296875em;
  color: rgb(255, 255, 0);
}
.metafi-flagkjngdom .path1:before {
  content: "\e9be";
  color: rgb(0, 36, 125);
}
.metafi-flagkjngdom .path2:before {
  content: "\e9bf";
  margin-left: -1.3681640625em;
  color: rgb(207, 27, 43);
}
.metafi-flagkjngdom .path3:before {
  content: "\e9c0";
  margin-left: -1.3681640625em;
  color: rgb(238, 238, 238);
}
.metafi-flagkjngdom .path4:before {
  content: "\e9c1";
  margin-left: -1.3681640625em;
  color: rgb(207, 27, 43);
}
.metafi-flagjapan .path1:before {
  content: "\e9c2";
  color: rgb(255, 255, 255);
}
.metafi-flagjapan .path2:before {
  content: "\e9c3";
  margin-left: -1.3154296875em;
  color: rgb(210, 47, 39);
}
.metafi-computer:before {
  content: "\e9bb";
}
.metafi-iconMetafiGetApp:before {
  content: "\e9ad";
}
.metafi-crypto:before {
  content: "\e9ba";
}
.metafi-wallet2:before {
  content: "\e9b9";
}
.metafi-circle:before {
  content: "\e916";
}
.metafi-about:before {
  content: "\e900";
}
.metafi-account:before {
  content: "\e901";
}
.metafi-activity1:before {
  content: "\e902";
}
.metafi-analytics:before {
  content: "\e903";
}
.metafi-android:before {
  content: "\e904";
}
.metafi-arrow-down-fill:before {
  content: "\e905";
}
.metafi-arrowLeft:before {
  content: "\e906";
}
.metafi-arrowLeftFillCircle:before {
  content: "\e907";
}
.metafi-arrowRight:before {
  content: "\e908";
}
.metafi-art:before {
  content: "\e909";
}
.metafi-availability:before {
  content: "\e90a";
}
.metafi-beta-game:before {
  content: "\e90b";
}
.metafi-bid:before {
  content: "\e90c";
}
.metafi-book:before {
  content: "\e90d";
}
.metafi-boxer:before {
  content: "\e90e";
}
.metafi-brrowing:before {
  content: "\e90f";
}
.metafi-business:before {
  content: "\e910";
}
.metafi-buy:before {
  content: "\e911";
}
.metafi-cancel:before {
  content: "\e912";
}
.metafi-card-switch:before {
  content: "\e913";
}
.metafi-careers:before {
  content: "\e914";
}
.metafi-cash:before {
  content: "\e915";
}
.metafi-coinMetafi:before {
  content: "\e917";
}
.metafi-collection:before {
  content: "\e918";
}
.metafi-colspand:before {
  content: "\e919";
}
.metafi-comd:before {
  content: "\e91a";
}
.metafi-comic:before {
  content: "\e91b";
}
.metafi-comment:before {
  content: "\e91c";
}
.metafi-comment-dots:before {
  content: "\e91d";
}
.metafi-community:before {
  content: "\e91e";
  color: #9c9fa9;
}
.metafi-contact:before {
  content: "\e91f";
  color: #9c9fa9;
}
.metafi-copy:before {
  content: "\e920";
}
.metafi-coupon:before {
  content: "\e921";
}
.metafi-credit:before {
  content: "\e922";
}
.metafi-currency:before {
  content: "\e923";
}
.metafi-chart:before {
  content: "\e924";
}
.metafi-dashboad:before {
  content: "\e925";
}
.metafi-delete:before {
  content: "\e926";
}
.metafi-developer:before {
  content: "\e927";
}
.metafi-docs:before {
  content: "\e928";
}
.metafi-dots:before {
  content: "\e929";
}
.metafi-dress:before {
  content: "\e92a";
}
.metafi-earth:before {
  content: "\e92b";
}
.metafi-ecosystem:before {
  content: "\e92c";
}
.metafi-ecosystem1:before {
  content: "\e92d";
}
.metafi-email:before {
  content: "\e92e";
}
.metafi-event:before {
  content: "\e92f";
}
.metafi-evnets:before {
  content: "\e930";
}
.metafi-expand:before {
  content: "\e931";
}
.metafi-eye:before {
  content: "\e932";
}
.metafi-facebook-messenger:before {
  content: "\e933";
}
.metafi-farm:before {
  content: "\e934";
}
.metafi-feedback:before {
  content: "\e935";
  color: #9c9fa9;
}
.metafi-filter:before {
  content: "\e936";
}
.metafi-fire:before {
  content: "\e937";
}
.metafi-game:before {
  content: "\e938";
}
.metafi-gold:before {
  content: "\e939";
}
.metafi-grid:before {
  content: "\e93a";
}
.metafi-ginsen:before {
  content: "\e93b";
}
.metafi-hand-money:before {
  content: "\e93c";
}
.metafi-hand .path1:before {
  content: "\e93d";
  color: rgb(61, 61, 62);
}
.metafi-hand .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path4:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path5:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path6:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path7:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path8:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path9:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path10:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path11:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path12:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path13:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path14:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(51, 51, 52);
}
.metafi-hand .path15:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(59, 59, 61);
}
.metafi-hand .path16:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path17:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path18:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(49, 49, 49);
}
.metafi-hand .path19:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(50, 50, 51);
}
.metafi-hand .path20:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(48, 48, 49);
}
.metafi-hand .path21:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(50, 50, 50);
}
.metafi-hand .path22:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(49, 49, 49);
}
.metafi-hand .path23:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(65, 65, 65);
}
.metafi-hand .path24:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(55, 55, 55);
}
.metafi-hand .path25:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(58, 58, 60);
}
.metafi-hand .path26:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(49, 49, 50);
}
.metafi-hand .path27:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(49, 49, 49);
}
.metafi-hand .path28:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(62, 62, 64);
}
.metafi-hand .path29:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(50, 50, 51);
}
.metafi-hand .path30:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(53, 53, 53);
}
.metafi-hand .path31:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path32:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(51, 51, 53);
}
.metafi-hand .path33:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.metafi-hand .path34:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(51, 51, 53);
}
.metafi-hand .path35:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(88, 88, 90);
}
.metafi-hand .path36:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(51, 51, 53);
}
.metafi-hand .path37:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(51, 51, 52);
}
.metafi-hand .path38:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(52, 52, 54);
}
.metafi-hand .path39:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path40:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path41:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path42:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path43:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path44:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(48, 48, 50);
}
.metafi-hand .path45:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-hand .path46:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(61, 61, 62);
}
.metafi-heart-plus:before {
  content: "\e96b";
}
.metafi-heart:before {
  content: "\e96c";
}
.metafi-hero-shard:before {
  content: "\e96d";
}
.metafi-hero:before {
  content: "\e96e";
}
.metafi-home:before {
  content: "\e96f";
  color: #d0d2d6;
}
.metafi-icon-metafi:before {
  content: "\e970";
  color: #0d6efd;
}
.metafi-idea:before {
  content: "\e971";
}
.metafi-indivi:before {
  content: "\e972";
}
.metafi-info:before {
  content: "\e973";
}
.metafi-introduce:before {
  content: "\e974";
  color: #9c9fa9;
}
.metafi-items:before {
  content: "\e975";
}
.metafi-land:before {
  content: "\e976";
}
.metafi-lending:before {
  content: "\e977";
}
.metafi-level-16:before {
  content: "\e978";
}
.metafi-like:before {
  content: "\e979";
}
.metafi-loan:before {
  content: "\e97a";
}
.metafi-logout:before {
  content: "\e97b";
  color: #9c9fa9;
}
.metafi-loyalty-rounded:before {
  content: "\e97c";
}
.metafi-mac-os:before {
  content: "\e97d";
}
.metafi-menu:before {
  content: "\e97e";
}
.metafi-metaverse:before {
  content: "\e97f";
}
.metafi-money:before {
  content: "\e980";
}
.metafi-more-vertical:before {
  content: "\e981";
}
.metafi-music:before {
  content: "\e982";
}
.metafi-mystery:before {
  content: "\e983";
}
.metafi-navi16:before {
  content: "\e984";
}
.metafi-network:before {
  content: "\e985";
}
.metafi-news:before {
  content: "\e986";
}
.metafi-night:before {
  content: "\e987";
}
.metafi-partner:before {
  content: "\e988";
}
.metafi-parts:before {
  content: "\e989";
}
.metafi-pay:before {
  content: "\e98a";
}
.metafi-pet:before {
  content: "\e98b";
}
.metafi-productions:before {
  content: "\e98c";
}
.metafi-productions1:before {
  content: "\e98d";
}
.metafi-rarity:before {
  content: "\e98e";
}
.metafi-real-estate:before {
  content: "\e98f";
}
.metafi-Receive:before {
  content: "\e990";
}
.metafi-recharge:before {
  content: "\e991";
}
.metafi-rent:before {
  content: "\e992";
}
.metafi-renting:before {
  content: "\e993";
}
.metafi-reward:before {
  content: "\e994";
}
.metafi-rocket:before {
  content: "\e995";
}
.metafi-sale:before {
  content: "\e996";
}
.metafi-sale-1:before {
  content: "\e997";
}
.metafi-search:before {
  content: "\e998";
}
.metafi-sell:before {
  content: "\e999";
}
.metafi-send:before {
  content: "\e99a";
}
.metafi-send-1:before {
  content: "\e99b";
}
.metafi-service:before {
  content: "\e99c";
}
.metafi-setting:before {
  content: "\e99d";
}
.metafi-share:before {
  content: "\e99e";
}
.metafi-sheild:before {
  content: "\e99f";
}
.metafi-shopping:before {
  content: "\e9a0";
}
.metafi-staking:before {
  content: "\e9a1";
}
.metafi-star:before {
  content: "\e9a2";
}
.metafi-status:before {
  content: "\e9a3";
}
.metafi-support:before {
  content: "\e9a4";
}
.metafi-swap:before {
  content: "\e9a5";
}
.metafi-sword:before {
  content: "\e9a6";
}
.metafi-team:before {
  content: "\e9a7";
}
.metafi-termsOfUse:before {
  content: "\e9a8";
  color: #9c9fa9;
}
.metafi-tiktok:before {
  content: "\e9a9";
}
.metafi-timer:before {
  content: "\e9aa";
}
.metafi-token:before {
  content: "\e9ab";
}
.metafi-topup:before {
  content: "\e9ac";
}
.metafi-thunder:before {
  content: "\e9ae";
}
.metafi-transform:before {
  content: "\e9af";
}
.metafi-transform-1:before {
  content: "\e9b0";
}
.metafi-upgrade:before {
  content: "\e9b1";
}
.metafi-UsagePolicy:before {
  content: "\e9b2";
  color: #9c9fa9;
}
.metafi-view:before {
  content: "\e9b3";
}
.metafi-wallet:before {
  content: "\e9b4";
}
.metafi-water-16:before {
  content: "\e9b5";
}
.metafi-win8:before {
  content: "\e9b6";
}
.metafi-withdrawal:before {
  content: "\e9b7";
}
.metafi-activity:before {
  content: "\e9b8";
}
.metafi-Star-5:before {
  content: "\eb5f";
}
.metafi-Star-1:before {
  content: "\eb60";
  color: #ffed47;
}
.metafi-Star-11:before {
  content: "\eb61";
}
.metafi-logos_linkedin-icon:before {
  content: "\ea30";
  color: #0a66c2;
}
.metafi-status1:before {
  content: "\ea31";
  color: #eaecf0;
}
.metafi-Rectangle-992:before {
  content: "\eb62";
}
.metafi-Rectangle-994:before {
  content: "\eb63";
  color: #ffce31;
}
.metafi-arrow-down-fill1:before {
  content: "\ea2d";
  color: #fff;
}
.metafi-arrow-up:before {
  content: "\ea2e";
  color: #28c76f;
}
.metafi-developer1:before {
  content: "\ea2f";
  color: #eaecf0;
}
.metafi-boxer1:before {
  content: "\e9c4";
  color: #d0d2d6;
}
.metafi-card-switch1:before {
  content: "\e9c5";
  color: #d0d2d6;
}
.metafi-cash1:before {
  content: "\e9c6";
  color: #d0d2d6;
}
.metafi-comment1:before {
  content: "\e9c7";
  color: #d0d2d6;
}
.metafi-currency1:before {
  content: "\e9c8";
  color: #d0d2d6;
}
.metafi-email1:before {
  content: "\e9c9";
  color: #d0d2d6;
}
.metafi-farm1:before {
  content: "\e9ca";
  color: #d0d2d6;
}
.metafi-Group-108 .path1:before {
  content: "\e9cb";
  color: rgb(54, 64, 92);
}
.metafi-Group-108 .path2:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(180, 208, 239);
}
.metafi-heart-plus1:before {
  content: "\e9cc";
  color: #6b97d4;
}
.metafi-heart1:before {
  content: "\e9cd";
}
.metafi-hero-shard1:before {
  content: "\e9ce";
  color: #d0d2d6;
}
.metafi-hero1:before {
  content: "\e9cf";
  color: #d0d2d6;
}
.metafi-indivi1:before {
  content: "\e9d0";
  color: #d0d2d6;
}
.metafi-items1:before {
  content: "\e9d1";
  color: #d0d2d6;
}
.metafi-land1:before {
  content: "\e9d2";
  color: #d0d2d6;
}
.metafi-loyalty-rounded1:before {
  content: "\e9d3";
  color: #d0d2d6;
}
.metafi-network1:before {
  content: "\e9d4";
  color: #d0d2d6;
}
.metafi-pay1:before {
  content: "\e9d5";
  color: #d0d2d6;
}
.metafi-pet1:before {
  content: "\e9d6";
  color: #d0d2d6;
}
.metafi-share1:before {
  content: "\e9d7";
  color: #d0d2d6;
}
.metafi-Star:before {
  content: "\e9d8";
}
.metafi-token1:before {
  content: "\e9d9";
  color: #d0d2d6;
}
.metafi-filter1:before {
  content: "\e9da";
  color: #d0d2d6;
}
.metafi-earth1:before {
  content: "\e9db";
  color: #d09a4d;
}
.metafi-leaf:before {
  content: "\e9dc";
  color: #28c76f;
}
.metafi-water:before {
  content: "\e9dd";
  color: #56ccf2;
}
.metafi-army-new:before {
  content: "\e9de";
  color: #47b009;
}
.metafi-Subtract:before {
  content: "\e9df";
  color: #d09a4d;
}
.metafi-navi161:before {
  content: "\e9e0";
  color: #00cfe8;
}
.metafi-Fire .path1:before {
  content: "\e9e1";
  color: rgb(234, 84, 85);
}
.metafi-Fire .path2:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(255, 159, 67);
}
.metafi-Fire .path3:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(255, 237, 71);
}
.metafi-air-icon:before {
  content: "\e9e2";
  color: #00f0ff;
}
.metafi-sword1:before {
  content: "\e9e3";
  color: #d09a4d;
}
.metafi-level-161:before {
  content: "\e9e4";
  color: #ffed47;
}
.metafi-sheild1:before {
  content: "\e9e5";
  color: #28c76f;
}
.metafi-heart2:before {
  content: "\e9e6";
  color: #ea5455;
}
.metafi-facebook1:before {
  content: "\e9e7";
  // color: #475993;
}
.metafi-youtube:before {
  content: "\e9e8";
  // color: #d7143a;
}
.metafi-user .path1:before {
  content: "\ea32";
  color: rgb(30, 79, 255);
}
.metafi-user .path2:before {
  content: "\ea37";
  margin-left: -1.025390625em;
  color: rgb(248, 248, 248);
}
.metafi-heart3:before {
  content: "\ea33";
  color: #ea5455;
}
.metafi-copyright:before {
  content: "\e9e9";
}
.metafi-twitter1:before {
  content: "\e9ea";
}
.metafi-email2:before {
  content: "\e9eb";
}
.metafi-file-zip:before {
  content: "\e9ec";
}
.metafi-android1:before {
  content: "\e9ed";
}
.metafi-apple:before {
  content: "\e9ee";
}
.metafi-qrcode-outline:before {
  content: "\e9ef";
}
.metafi-user1:before {
  content: "\e9f0";
}
.metafi-wallet21:before {
  content: "\e9f1";
}
.metafi-bell:before {
  content: "\e9f2";
}
.metafi-buy2:before {
  content: "\e9f3";
}
.metafi-search-outline:before {
  content: "\e9f4";
}
.metafi-money2:before {
  content: "\e9f5";
}
.metafi-support1:before {
  content: "\e9f6";
}
.metafi-discord-fill:before {
  content: "\e9f7";
}
.metafi-evnets1:before {
  content: "\e9f8";
}
.metafi-iconmetafi:before {
  content: "\e9f9";
}
.metafi-about1:before {
  content: "\e9fa";
}
.metafi-careers1:before {
  content: "\e9fb";
}
.metafi-book1:before {
  content: "\e9fc";
}
.metafi-news1:before {
  content: "\e9fd";
}
.metafi-team1:before {
  content: "\e9fe";
}
.metafi-coinmetafi:before {
  content: "\e9ff";
}
.metafi-ecosystem2:before {
  content: "\ea00";
}
.metafi-setting1:before {
  content: "\ea01";
}
.metafi-business1:before {
  content: "\ea02";
}
.metafi-indivi2:before {
  content: "\ea03";
}
.metafi-productions2:before {
  content: "\ea04";
}
.metafi-grid1:before {
  content: "\ea05";
}
.metafi-circle1:before {
  content: "\ea06";
}
.metafi-beta-game1:before {
  content: "\ea07";
}
.metafi-swap1:before {
  content: "\ea08";
}
.metafi-hand-money1:before {
  content: "\ea09";
}
.metafi-wallet1:before {
  content: "\ea0a";
}
.metafi-reward1:before {
  content: "\ea0b";
}
.metafi-music1:before {
  content: "\ea0c";
}
.metafi-collection1:before {
  content: "\ea0d";
}
.metafi-art1:before {
  content: "\ea0e";
}
.metafi-real-estate1:before {
  content: "\ea0f";
}
.metafi-tiktok1:before {
  content: "\ea10";
}
.metafi-telegram1:before {
  content: "\ea11";
}
.metafi-money1:before {
  content: "\ea12";
}
.metafi-hand1:before {
  content: "\ea13";
}
.metafi-money3:before {
  content: "\ea14";
}
.metafi-idea1:before {
  content: "\ea15";
}
.metafi-loan1:before {
  content: "\ea16";
}
.metafi-renting1:before {
  content: "\ea17";
}
.metafi-brrowing1:before {
  content: "\ea18";
}
.metafi-lending1:before {
  content: "\ea19";
}
.metafi-transform1:before {
  content: "\ea1a";
}
.metafi-withdrawal1:before {
  content: "\ea1b";
}
.metafi-upgrade1:before {
  content: "\ea1c";
}
.metafi-sale1:before {
  content: "\ea1d";
}
.metafi-topup1:before {
  content: "\ea1e";
}
.metafi-gold1:before {
  content: "\ea1f";
}
.metafi-Receive1:before {
  content: "\ea20";
}
.metafi-send1:before {
  content: "\ea21";
}
.metafi-recharge1:before {
  content: "\ea22";
}
.metafi-mystery1:before {
  content: "\ea23";
}
.metafi-dress1:before {
  content: "\ea24";
}
.metafi-ginsen1:before {
  content: "\ea25";
}
.metafi-real-world:before {
  content: "\ea26";
}
.metafi-comic1:before {
  content: "\ea27";
}
.metafi-dashboad1:before {
  content: "\ea28";
}
.metafi-game1:before {
  content: "\ea29";
}
.metafi-home1:before {
  content: "\ea2a";
}
.metafi-metaverse1:before {
  content: "\ea2b";
}
.metafi-shopping1:before {
  content: "\ea2c";
}
